import React from "react";
import styled from "styled-components";
import { FaLinkedinIn, FaFacebook, FaInstagram, FaGithub } from "react-icons/fa";

const ProfComponent = () => {
  return (
    <Container id="home">
        <Texts>
          <h4>
            Hello <span className="green">I'am</span>
          </h4>
          <h1 className="green">Zeeshan Aziz</h1>
          <h3>Cloud Engineer and Cloud Solution Architect Expert</h3>
          <p>
            I’m Azure Cloud Engineer and Microsoft Certified Azure Solution Architect Expert.
            Moreover, I am also Microsoft Certified Trainer (MCT) and Azure Data Engineer.
            Currently designing, developing and administering cloud solutions by using azure services.
            Also providing trainings to IT professionals with hands on labs for Azure Cloud.
          </p>
          <button><a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1eInueKILmlap_HMY_bz3V0Z5n6B7ifG2/view?usp=sharing">Download CV</a></button>
          &nbsp;
          <button><a target="_blank" rel="noopener noreferrer" href="https://www.upwork.com/freelancers/~015385e0acfc0a4871">Let's talk</a></button>
          <Social>
            <p>Check out my</p>
            <div className="social-icons">
              <span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/zeeshan0107">
                  <FaLinkedinIn />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/Zeeshan0107">
                  <FaGithub />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/0107z">
                  <FaFacebook />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/zeeshan0107">
                  <FaInstagram />
                </a>
              </span>
            </div>
          </Social>
        </Texts>
        <Profile>
          <img
            src="https://prodzee.blob.core.windows.net/docs/p1.png"
            alt="profile"
          />
        </Profile>
    </Container>
  );
};

export default ProfComponent;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const Texts = styled.div`
  flex: 1;
  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 2rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-weight: 300;
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 3rem;
    cursor: pointer;
    background-color: #01be96;
    border: none;
    color: #fff;
    font-weight: 500;
    filter: drop-shadow(0px 10px 10px #01be9551);
    :hover {
      filter: drop-shadow(0px 10px 10px #01be9570);
    }
    a {
      color: #fff;
    }
  }
`;
const Social = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #01be96;
      position: relative;
      transition: transform 400ms ease-in-out;
      :hover {
        transform: rotate(360deg);
      }
    }

    a {
      color: #fff;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Profile = styled.div`
  img {
    width: 20rem;
    filter: drop-shadow(0px 10px 10px #01be9570);
    transition: transform 400ms ease-in-out;
    @media (max-width: 790px) {
      width: 20rem;
    }

    @media (max-width: 660px) {
      width: 18rem;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  :hover img {
    transform: translateY(-10px);
  }
`;