import React from "react";
import { MdOutlineDeveloperMode } from "react-icons/md";
import { AiOutlineCloudServer, AiOutlineCloudSync, AiOutlineAppstoreAdd, AiFillDatabase } from "react-icons/ai";
import { SiAzurepipelines } from "react-icons/si";
import styled from "styled-components";
import Card from "./Card";

const Services = () => {
  return (
    <Container id="service">
        <h4>
          My <span className="green">Services</span>
        </h4>
        <h1>What I Do</h1>
      <Cards>
          <Card
            Icon={AiOutlineCloudServer}
            title={"Cloud Solution Architect Expert"}
            disc={`Deep understanding of Azure cloud services, networking, security, and identity management concepts. 
            I also have experience in designing and implementing highly available, scalable, and secure cloud-based solutions.`}
          />
          <Card
            Icon={MdOutlineDeveloperMode}
            title={"Cloud Development & Integration"}
            disc={`Specializes in designing and implementing cloud-based applications using the .NET and the Azure cloud. 
            I have excellent communication and collaboration skills to work effectively with other developers, architects, and IT teams.`}
          />
          <Card
            Icon={AiOutlineCloudSync}
            title={"Hybrid Cloud Migration Solution"}
            disc={`Accelerate data modernization & peerless specializes in migrating resources to compliant clouds with minimal disruption. 
            I help you migrate your infrastructure, applications and workloads from on-premise IT system to the cloud.`}
          />
          <Card
            Icon={SiAzurepipelines}
            title={"Azure DevOps Engineer Expert"}
            disc={`Providing build and release services to support CI/CD of applications, allows to share packages from public and private sources
            and handles a suite of Agile tools to support planning and tracking work, code defects, source control of code and issues`}
          />
          <Card
            Icon={AiFillDatabase}
            title={"Azure Data Engineer Associate"}
            disc={`Expertise in integrating, transforming, and consolidating data from various structured, unstructured, 
            and streaming data systems into a suitable schema for building analytics solutions. Also design, implement, monitor, and optimize data platforms`}
          />
          <Card
            Icon={AiOutlineAppstoreAdd}
            title={".Net Application Development"}
            disc={`Building modern, multi-platform, natively compiled macOS and Windows desktop apps, as well as Android and iOS mobile apps, 
            and independently deployable microservices that run on containers using C# and XAML in a single codebase.`}
          />
      </Cards>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
