import React from "react";
import styled from "styled-components";
import { MdAlternateEmail } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { HiOutlineMailOpen } from "react-icons/hi";
import { AiOutlineArrowUp } from "react-icons/ai";
import { FaLinkedinIn, FaFacebook, FaInstagram, FaGithub } from "react-icons/fa";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { Fade } from "react-awesome-reveal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const handleFormSubmit = async (e) => {
  // Get form data
  const fullname = document.getElementById('fullname').value;
  const email = document.getElementById('email').value;
  const message = document.getElementById('message').value;
  if(fullname === null || fullname === undefined || fullname.trim() === ''){
    // alert("Thanks for contacting us! We'll get back to you soon.");
    toast.error("Please enter your name.");
  }
  else if(email === null || email === undefined || email.trim() === ''){
    // alert("Thanks for contacting us! We'll get back to you soon.");
    toast.error("Please enter your email address.");
  }
  else if(message === null || message === undefined || message.trim() === ''){
    // alert("Thanks for contacting us! We'll get back to you soon.");
    toast.error("Please enter your message.");
  }
  else{

    // Create email content
    const msg = {
      to: 'zeeshanaziz0107@gmail.com',
      from: "contact@muhammadimran.me",
      subject: 'Contact Us',
      html: `<p><strong>Full Name:</strong> ${fullname}</p>
            <p><strong>Email:</strong> ${email}</p>
            <p><strong>Message:</strong> ${message}</p>`,
    };

    // Send email
    try {
      const url = 'https://prod-21.eastasia.logic.azure.com:443/workflows/e5c6569ca1024d47900780ed0cbbfef5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=edR5Qlvz9iPjtMrMz1T_vZ4rEBKFDy3VPZH3qUX-i2M';
      const headers = {
        'Content-Type': 'application/json',
      };

      const email = {
        personalizations: [
          {
            to: [
              {
                email: msg.to
              }
            ],
            subject: msg.subject
          }
        ],
        from: {
          email: msg.from
        },
        content: [
          {
            type: 'text/html',
            value: msg.html
          }
        ]
      };

      const response = await axios.post(url, email, { headers });
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Failed to send email:', error);
    }
    // alert("Thanks for contacting us! We'll get back to you soon.");
    toast.success("Thanks for contacting us! We'll get back to you soon.");
    // Empty form fields
    document.getElementById('fullname').value = '';
    document.getElementById('email').value = '';
    document.getElementById('message').value = '';
  }
  e.preventDefault();
};


const Footer = () => {
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Container id="contact">
      <Profile>
          <h1>Contact</h1>
        <div className="address">
            <h1>Address:</h1>
            <p>Lahore, Pakistan</p>
        </div>
        <div className="links">
            <h1>Contact me directly:</h1>
          <div>
            <span>
              <FiPhoneCall />
            </span>
              <a href="tel:+923064490343">+92 306-4490343</a>
          </div>
          <div>
              <span>
                <HiOutlineMailOpen />
              </span>
              <a href="mailto:zeeshanaziz0107@gmail.com">zeeshanaziz0107@gmail.com</a>
          </div>
        </div>
        <div className="profiles">
            <h1>Check my profiles</h1>
          <div className="icons">
              <span>
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/zeeshan0107">
                  <FaGithub />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/zeeshan0107">
                  <FaLinkedinIn />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/0107z">
                  <FaFacebook />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/zeeshan0107">
                  <FaInstagram />
                </a>
              </span>
          </div>
        </div>
        <Fade>
          <ArrowUp onClick={scrollUp}>
            <AiOutlineArrowUp />
          </ArrowUp>
        </Fade>
      </Profile>
      <Form>
          <form>
            <div className="name">
              <span>
                <CgProfile />
              </span>
              <input type="text" placeholder="Fullname..." id="fullname" required/>
            </div>
            <div className="email">
              <span>
                <MdAlternateEmail />
              </span>
              <input type="email" placeholder="Email..." id="email" required/>
            </div>
            <div className="message">
              <span className="messageIcon">
                <FiMail />
              </span>
              <textarea cols="30" rows="10" placeholder="Message..." id="message" required></textarea>
            </div>
            <button type="submit" onClick={handleFormSubmit}>Submit</button>
          </form>
      </Form>
      <ToastContainer />
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  margin-top: 2rem;
  position: relative;
  padding: 2rem 0;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 3rem;
  }
`;
const Profile = styled.div`
  flex: 1;
  .address {
    padding: 1rem 0;
    h1 {
      font-size: 1.2rem;
    }

    p {
      width: 60%;
      padding-top: 0.5rem;
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }

  .links {
    h1 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      a {
        text-decoration: none;
        color: lightgray;
        :hover {
          color: orange;
        }
      }
    }
  }

  .profiles {
    h1 {
      font-size: 1.2rem;
      padding: 1rem 0;
    }

    .icons {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        border-radius: 50px;

        :hover {
          background-color: orange;
        }

        a {
          margin-top: 0.2rem;
          color: #fff;
        }
      }
    }
  }
`;
const ArrowUp = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #01be96;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  @media (max-width: 650px) {
    position: absolute;
    right: 3rem;
    top: 16rem;
  }
`;
const Form = styled.div`
  flex: 1;
  h1 {
    font-size: 1.3rem;
    padding-bottom: 0.7rem;
  }

  form {
    background-color: #191923;
    padding: 0.8rem;
    border-radius: 5px;
    .name,
    .email,
    .message {
      display: flex;
      border: 1px solid gray;
      margin-bottom: 0.5rem;
      input,
      textarea {
        width: 100%;
        border: none;
        outline: none;
        color: #fff;
        background-color: transparent;
        padding: 1rem 0.5rem;
      }
      span {
        background-color: #3e3e3e;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .messageIcon {
        align-items: flex-start;
        padding-top: 0.5rem;
      }
    }

    button {
      width: 5rem;
      height: 1.8rem;
      background-color: #01be96;
      border: none;
      border-radius: 5px;
      filter: drop-shadow(0px 4px 5px #01be9551);
      cursor: pointer;
      :hover {
        filter: drop-shadow(0px 6px 9px #01be9551);
      }
    }
  }
`;
