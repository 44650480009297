import styled from "styled-components";
import Header from "./components/Banner/Header";
import ProfComponent from "./components/Banner/ProfComponent";
import Services from "./components/Service/Services";
import Projects from "./components/Projects/Projects";
import Certifications from "./components/Certifications/Certifications";
// import Clients from "./components/Clients/Clients";
// import Blogs from "./components/Blogs/Blogs"; 
import Footer from "./components/Footer/Footer";
// import React, {useState, useEffect} from 'react';

function App() {
  // const [isLoading, setLoading] = useState(true);
  
  //   function someRequest() { //Simulates a request; makes a "promise" that'll run for 2.5 seconds
  //     return new Promise(resolve => setTimeout(() => resolve(), 2500));
  //   } 

  //   useEffect(() => {
  //     someRequest().then(() => {
  //       const loaderElement = document.querySelector(".loader-container");
  //       if (loaderElement) {
  //         loaderElement.remove();
  //         setLoading(!isLoading);
  //       }
  //     });
  //   });

  //   if (isLoading) { //
  //     return null;
  //   }
  return (
    <Container>
      <LightColor>
        <Header />
      </LightColor>
      <Banner>
        <ProfComponent />
      </Banner>
      <Services />
      <LightColor>
        <Projects />
      </LightColor>
      <Certifications />
      {/* <LightColor>
        <Clients />
      </LightColor> */}
      
      {/* <LightColor>
        <Blogs />
      </LightColor> */}
      <Footer />
    </Container>
  );
}

export default App;

const Container = styled.div``;
const Banner = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
  height: 100%;
  padding-bottom: 2rem;
  @media (max-width: 640px) {
    height: 100%;
    padding-bottom: 2rem;
  }
`;

const LightColor = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
`;